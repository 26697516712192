<template>
  <div class="pages-container">
    <div class="app-header">
      <Header />
    </div>
    <div class="app-container">
      <router-view v-if="show"></router-view>
    </div>
    <div class="app-footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import Header from "components/header";
import Footer from "components/footer";
import { mapGetters } from "vuex";

export default {
  name: "pages",
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters(["account"])
  },
  watch: {
    $route: "routeChange",
    account: "accountChange"
  },
  created() {
    this.show = !this.$route.meta.requiresAuth || this.account.id;
  },
  methods: {
    routeChange() {
      this.show = !this.$route.meta.requiresAuth || this.account.id;
    },
    accountChange() {
      this.show = !this.$route.meta.requiresAuth || this.account.id;
    }
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style src="./index.scss" lang="scss" />
